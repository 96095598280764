import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  // const [nextRace, setNextRace] = useState(0);
  const [nextRaceDays, setNextRaceDays] = useState(0);
  const [nextRaceHours, setNextRaceHours] = useState(0);
  const [nextRaceMinutes, setNextRaceMinutes] = useState(0);
  const [nextRaceSeconds, setNextRaceSeconds] = useState(0);
  const [nextRaceMilliseconds, setNextRaceMilliseconds] = useState(0);



  useEffect(() => {
    let interval = setInterval(() => {
      const nextRace = new Date('2022-03-20T15:00:00').getTime() - Date.now();
      const fullDays = Math.floor(nextRace / (1000 * 60 * 60 * 24))
      const fullHours = Math.floor(nextRace / (1000 * 60 * 60) - fullDays * 24);
      const fullMinutes = Math.floor(nextRace / (1000 * 60) - fullHours * 60 - fullDays * 60 * 24);
      const fullSeconds = Math.floor(nextRace / 1000 - fullMinutes * 60 - fullHours * 60 * 60 - fullDays * 60 * 60 * 24);
      const fullMilliseconds = Math.floor(nextRace - fullSeconds * 1000 - fullMinutes * 60 * 1000 - fullHours * 1000 * 60 * 60 - fullDays * 1000 * 60 * 60 * 24);
      setNextRaceDays(fullDays);
      setNextRaceHours(fullHours);
      setNextRaceMinutes(fullMinutes);
      setNextRaceSeconds(fullSeconds);
      setNextRaceMilliseconds(fullMilliseconds);
    }, 1);
  }, [nextRaceSeconds]);

  return (
    <div className="App">
      <header className="App-header">
        <div className='Cards-container'>
          <div className='Cards-header'>
            <div className='Cards-header-left-border'></div>
            <div className='Cards-header-top-border'></div>
            <div className='Cards-header-content'>DaysToF1Race.com</div>
          </div>
          <div className='Card'>
            <div className="Card-title">Next Race – Bahrain</div>
            <div className="Card-content">
              <div className="Card-daysto">{nextRaceDays} day{nextRaceDays === 1 ? '' : 's'} to the next F1 race</div>
              {`and 
              ${nextRaceHours} hour${nextRaceHours === 1 ? '' : 's'}
              ${nextRaceMinutes} minute${nextRaceMinutes === 1 ? '' : 's'}
              ${nextRaceSeconds} second${nextRaceSeconds === 1 ? '' : 's'}`}
            </div>
          </div>
          {/* <div className='Card'>
            <div className="Card-title">Next races</div>
          </div> */}
        </div>
        <a className='author' href='https://www.linkedin.com/in/max-gajek/'>By Max Gajek</a>
      </header>
    </div>
  );
}

export default App;
